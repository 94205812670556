var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"mobile indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page mlist flex-col justify-start",staticStyle:{"height":"auto"}},[_c('div',{staticClass:"mswiper windowheight"},[_c('top-b',{attrs:{"kind":`list`,"topbarhide":_vm.topbarhide,"tb":_vm.tb,"w750":_vm.w750,"topbarOpacity":_vm.topbarOpacity,"lag":_vm.lag}}),_c('div',{staticClass:"swiper sc"},[_c('div',{class:`swiper-wrapper`},[_c('div',{class:`swiper-slide ssm0`},[_c('div',{staticClass:"swiper lsc0"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"group_0_3 flex-col"},[_c('div',{staticClass:"g03 flex-col",style:(`min-height:${_vm.pageHeight}px;`)},[_c('img',{staticClass:"image_20",staticStyle:{"width":"1760px","height":"1px","margin":"0 auto"},attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng43e13759fd61200dd2aec8f3f42b50d1271142b403a2181742c322bf702eb876"}}),_c('div',{staticClass:"g03in flex-col",style:(`min-height:${_vm.pageHeight}px;`)},[_c('div',{staticClass:"gbar flex-col justify-between",staticStyle:{"margin-top":"220px"}},[_c('div',{staticClass:"gtitles flex-col"},[_c('span',{staticClass:"text_13",staticStyle:{"color":"rgba(50, 50, 50, 1)","height":"64px","line-height":"64px"}},[_c('img',{staticStyle:{"height":"64px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/title4b.png"}})])]),_c('div',{staticClass:"gdes flex-col"},[_c('div',{staticClass:"textline",staticStyle:{"margin-top":"21px","color":"rgba(50, 50, 50, 1)","font-size":"26px","line-height":"26px"}},[_vm._v(" 趣野活动精彩不断，一起体验趣野无限。 ")])]),_c('div',{staticClass:"selectbar",staticStyle:{"width":"535px"}},[_c('div',{class:`sbitem ${
                                      _vm.sbindex == '' ? 'sbselected' : ''
                                    }`,staticStyle:{"width":"131px"},on:{"click":() => {
                                        _vm.sbselect('');
                                      }}},[_vm._v(" 全部 ")]),_c('div',{class:`sbitem ${
                                      _vm.sbindex == '0' ? 'sbselected' : ''
                                    }`,on:{"click":() => {
                                        _vm.sbselect('0');
                                      }}},[_vm._v(" 招募中 ")]),_c('div',{class:`sbitem ${
                                      _vm.sbindex == '1' ? 'sbselected' : ''
                                    }`,on:{"click":() => {
                                        _vm.sbselect('1');
                                      }}},[_vm._v(" 精彩回放 ")])])]),_c('div',{staticClass:"g03mlist"},[_vm._l((_vm.clist),function(item,index){return [_c('div',{key:index,class:`g03div`,on:{"click":() => {
                                        _vm.godetall(
                                          item.url,
                                          item.pname,
                                          item.kind,
                                          item.id
                                        );
                                      }}},[_c('div',{staticClass:"g03item",style:(`background-image: url(${
                                        _vm.baseUrl + item.imgurl
                                      });`)},[(item.kind == 40)?[(item.state == '0')?_c('div',{staticClass:"zmb zmbf"},[_vm._v(" 敬请期待 ")]):_vm._e(),(item.state == '1')?_c('div',{staticClass:"zmb"},[_vm._v(" 招募中 ")]):_vm._e(),(item.state == '2')?_c('div',{staticClass:"zmb zmbs"},[_vm._v(" 报名截止 ")]):_vm._e(),(item.state == '3')?_c('div',{staticClass:"zmb zmbs"},[_vm._v(" 已结束 ")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"g03info"},[_c('div',{staticClass:"g03title",domProps:{"innerHTML":_vm._s(item.pname)}}),_c('div',{staticClass:"memberpn flex-row"},[(item.photo)?_c('div',{staticClass:"photo",style:(`background-image:url(${item.photo});`)}):_c('div',{staticClass:"photo"}),_c('div',{staticClass:"memberpninfo flex-col"},[_c('div',{staticClass:"pname"},[_vm._v(" ALL FIELDS"+_vm._s(item.name)+" ")]),_c('div',{staticClass:"paddtime",domProps:{"innerHTML":_vm._s(item.addtime)}})])])])])]})],2),_c('div',{staticClass:"pageselect"},[_c('a-pagination',{attrs:{"size":"small","total":_vm.ctotal,"page-size":_vm.cpageSize},on:{"change":_vm.PageonChange},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)]),_c('div',{staticClass:"backimg"})])])])])])]),_c('div',{class:`swiper-slide ssm0`},[_c('footer-b',{attrs:{"kind":`index`,"g8top":_vm.g8top,"g8left":_vm.g8left,"lag":_vm.lag}})],1)])])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }